/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

import Newsletter from "./sections/Newsletter";
import StateImage from "./images/StateImages";
import MapsToProducts from "./sections/MapsToProducts";

function CustomLanding() {

  return (
    <>
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          // backgroundColor: "#E6F2F0",
          backgroundColor: "#dcf0fa",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6}>
              <MKTypography
                variant="h1"
                color="#1A3A3A"
                mb={3}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Get The Income Data You Need By Zip Code
              </MKTypography>
              <MKTypography variant="body1" color="#2C5454" opacity={0.9} mb={3}>
                Elevate your data-driven decisions with comprehensive income insights across the USA.
              </MKTypography>
              <Stack direction="column" spacing={1} mb={3}>
                <MKTypography variant="body1" color="#2C5454">
                  <Icon>check</Icon> Accurate, up-to-date information
                </MKTypography>
                <MKTypography variant="body1" color="#2C5454">
                  <Icon>check</Icon> Detailed breakdowns by zip code
                </MKTypography>
                <MKTypography variant="body1" color="#2C5454">
                  <Icon>check</Icon> Mean and Median income date
                </MKTypography>
              </Stack>
              <MKBox display="flex" flexDirection="column" alignItems="center" mb={2}>
                <MKButton variant="gradient" color="warning" size="large" component={Link} to='/income-by-zipcode/usa' mb={2}>
                  Get Started
                </MKButton>
                <MKTypography variant="caption" color="#2C5454" mt={2}>
                  100% Satisfaction Guarantee
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox
                sx={{
                  borderRadius: "30%", // Changed to 50% for a perfect oval
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  overflow: "hidden",
                  aspectRatio: "4 / 3",
                  maxWidth: "100%",
                  margin: "0 auto",
                }}
              >
                <MKBox
                  component="img"
                  src={StateImage("usa")}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
            p: 1,
            mx: 'auto',
            mt: -8,
            mb: 4,
            maxWidth: '120vh',
        }}
      >
        <MapsToProducts />

        <Newsletter text={"Empower your decisions with data! Subscribe now to get exclusive access to cutting-edge census insights and analytics. Don't miss out on the knowledge that shapes the future."}/>
       
      </Card>
      <MKBox pt={6} px={1} mt={6}>
      </MKBox>
    </>
  );
}

export default CustomLanding;