import React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


const sitemap = [
    {
      loc: 'https://terragrasp.com',
      display_name: 'Home',
    },
    {
      loc: 'https://terragrasp.com/about-us',
      display_name: 'About Us',
    },
    {
      loc: 'https://terragrasp.com/contact-us',
      display_name: 'Contact Us',
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/usa',
      display_name: 'Income by Zipcode',
    },
    {
      loc: 'https://terragrasp.com/user/cart',
      display_name: 'User Cart',
    },
  ];
  
  const SitemapLink = () => {
    return (
      <>
        <MKBox component="section">
          <Container sx={{ marginTop: '1vh', pb: { xs: 1, md: 10 } }}>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              borderRadius="16px"
              sx={{ 
                textAlign: "center", 
                background: 'white', 
                padding: '2rem'
              }}
            >
              <MKTypography variant="h1" mb={2} sx={{ textAlign: 'center' }}>
                Sitemap
              </MKTypography>
  
              <div style={{ textAlign: 'left', marginBottom: '1vh', width: '90%' }}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }}>TerraGrasp Site Map</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sitemap.map((url, index) => (
                      <tr key={index}>
                        <td style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                          <li>
                            <a href={url.loc}>{url.display_name}</a>
                          </li>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Container>
        </MKBox>
      </>
    );
  };
  
  export default SitemapLink;