import React from 'react';
import Icon from "@mui/material/Icon";
import StorageIcon from '@mui/icons-material/Storage';

const routes = [
  {
    name: "Get Data",
    icon: <StorageIcon/>,
    route: "/income-by-zipcode/usa",
  },
  {
    name: "contact us",
    icon: <Icon>send</Icon>,
    route: "/contact-us",
  },
  {
    name: "About us",
    icon: <Icon>house</Icon>,
    route: "/about-us",
  },
  {
    name: "account",
    icon: <Icon>person</Icon>,
    route: "/user/login",
    collapse: [
      {
        name: "sign in",
        route: "/user/login",
      },
      {
        name: "sign up",
        route: '/user/sign-up',
      },
    ],
  },
];

export default routes;

const signed_in_routes = [
  {
    name: "Get Data",
    icon: <StorageIcon/>,
    route: "/income-by-zipcode/usa",
  },
  {
    name: "contact us",
    icon: <Icon>send</Icon>,
    route: "/contact-us",
  }, 
  {
    name: "About us",
    icon: <Icon>house</Icon>,
    route: "/about-us",
  },
  {
    name: "account",
    icon: <Icon>person</Icon>,
    route: '/account',
    collapse: [
      { 
        name: "logout",
        route: '/user/logout',
      },
      { 
        name: "my account",
        route: '/account',
      },
    ],
  },
];



const admin_routes = [
  {
    name: "admin",
    icon: <Icon>admin</Icon>,
    route: 'admin/panel',
    collapse: [
      {
        name: 'Admin panel',
        route: 'admin/panel'
      },
      {
        name: 'create-product',
        route: 'admin/create-product'
      },
      {
        name: 'stripe_products',
        route: 'admin/stripe_products'
      },
      {
        name: 'stripe_prices',
        route: 'admin/stripe_prices'
      },
      {
        name: 'products',
        route: 'admin/products'
      },
      {
        name: 'detailed-product/:id',
        route: 'admin/detailed-product/:id'
      },
    ],
  },
];
export {admin_routes, signed_in_routes};
