import React from 'react';
import ALABAMA from "../../assets/images/state_median_income/Alabama.webp"
import ALASKA from "../../assets/images/state_median_income/Alaska.webp"
import ARIZONA from "../../assets/images/state_median_income/Arizona.webp"
import ARKANSAS from "../../assets/images/state_median_income/Arkansas.webp"
import CALIFORNIA from "../../assets/images/state_median_income/California.webp"
import COLORADO from "../../assets/images/state_median_income/Colorado.webp"
import CONNECTICUT from "../../assets/images/state_median_income/Connecticut.webp"
import DELAWARE from "../../assets/images/state_median_income/Delaware.webp"
import FLORIDA from "../../assets/images/state_median_income/Florida.webp"
import GEORGIA from "../../assets/images/state_median_income/Georgia.webp"
import HAWAII from "../../assets/images/state_median_income/Hawaii.webp"
import IDAHO from "../../assets/images/state_median_income/Idaho.webp"
import ILLINOIS from "../../assets/images/state_median_income/Illinois.webp"
import INDIANA from "../../assets/images/state_median_income/Indiana.webp"
import IOWA from "../../assets/images/state_median_income/Iowa.webp"
import KANSAS from "../../assets/images/state_median_income/Kansas.webp"
import KENTUCKY from "../../assets/images/state_median_income/Kentucky.webp"
import LOUISIANA from "../../assets/images/state_median_income/Louisiana.webp"
import MAINE from "../../assets/images/state_median_income/Maine.webp"
import MARYLAND from "../../assets/images/state_median_income/Maryland.webp"
import MASSACHUSETTS from "../../assets/images/state_median_income/Massachusetts.webp"
import MICHIGAN from "../../assets/images/state_median_income/Michigan.webp"
import MINNESOTA from "../../assets/images/state_median_income/Minnesota.webp"
import MISSISSIPPI from "../../assets/images/state_median_income/Mississippi.webp"
import MISSOURI from "../../assets/images/state_median_income/Missouri.webp"
import MONTANA from "../../assets/images/state_median_income/Montana.webp"
import NEBRASKA from "../../assets/images/state_median_income/Nebraska.webp"
import NEVADA from "../../assets/images/state_median_income/Nevada.webp"
import NEW_HAMPSHIRE from "../../assets/images/state_median_income/New_Hampshire.webp"
import NEW_JERSEY from "../../assets/images/state_median_income/New_Jersey.webp"
import NEW_MEXICO from "../../assets/images/state_median_income/New_Mexico.webp"
import NEW_YORK from "../../assets/images/state_median_income/New_York.webp"
import NORTH_CAROLINA from "../../assets/images/state_median_income/North_Carolina.webp"
import NORTH_DAKOTA from "../../assets/images/state_median_income/North_Dakota.webp"
import OHIO from "../../assets/images/state_median_income/Ohio.webp"
import OKLAHOMA from "../../assets/images/state_median_income/Oklahoma.webp"
import OREGON from "../../assets/images/state_median_income/Oregon.webp"
import PENNSYLVANIA from "../../assets/images/state_median_income/Pennsylvania.webp"
import RHODE_ISLAND from "../../assets/images/state_median_income/Rhode_Island.webp"
import SOUTH_CAROLINA from "../../assets/images/state_median_income/South_Carolina.webp"
import SOUTH_DAKOTA from "../../assets/images/state_median_income/South_Dakota.webp"
import TENNESSEE from "../../assets/images/state_median_income/Tennessee.webp"
import TEXAS from "../../assets/images/state_median_income/Texas.webp"
import USA from "../../assets/images/state_median_income/Usa.webp"
import UTAH from "../../assets/images/state_median_income/Utah.webp"
import VERMONT from "../../assets/images/state_median_income/Vermont.webp"
import VIRGINIA from "../../assets/images/state_median_income/Virginia.webp"
import WASHINGTON from "../../assets/images/state_median_income/Washington.webp"
import WEST_VIRGINIA from "../../assets/images/state_median_income/West_Virginia.webp"
import WISCONSIN from "../../assets/images/state_median_income/Wisconsin.webp"
import WYOMING from "../../assets/images/state_median_income/Wyoming.webp"
import USA_RECTANGLE from "../../assets/images/state_median_income/Usa_rectangle.webp"


const state_median_income = {
    "alabama":ALABAMA,
    "alaska":ALASKA,
    "arizona":ARIZONA,
    "arkansas":ARKANSAS,
    "california":CALIFORNIA,
    "colorado":COLORADO,
    "connecticut":CONNECTICUT,
    "delaware":DELAWARE,
    "florida":FLORIDA,
    "georgia":GEORGIA,
    "hawaii":HAWAII,
    "idaho":IDAHO,
    "illinois":ILLINOIS,
    "indiana":INDIANA,
    "iowa":IOWA,
    "kansas":KANSAS,
    "kentucky":KENTUCKY,
    "louisiana":LOUISIANA,
    "maine":MAINE,
    "maryland":MARYLAND,
    "massachusetts":MASSACHUSETTS,
    "michigan":MICHIGAN,
    "minnesota":MINNESOTA,
    "mississippi":MISSISSIPPI,
    "missouri":MISSOURI,
    "montana":MONTANA,
    "nebraska":NEBRASKA,
    "nevada":NEVADA,
    "new_hampshire":NEW_HAMPSHIRE,
    "new_jersey":NEW_JERSEY,
    "new_mexico":NEW_MEXICO,
    "new_york":NEW_YORK,
    "north_carolina":NORTH_CAROLINA,
    "north_dakota":NORTH_DAKOTA,
    "ohio":OHIO,
    "oklahoma":OKLAHOMA,
    "oregon":OREGON,
    "pennsylvania":PENNSYLVANIA,
    "rhode_island":RHODE_ISLAND,
    "south_carolina":SOUTH_CAROLINA,
    "south_dakota":SOUTH_DAKOTA,
    "tennessee":TENNESSEE,
    "texas":TEXAS,
    "usa":USA,
    "utah":UTAH,
    "vermont":VERMONT,
    "virginia":VIRGINIA,
    "washington":WASHINGTON,
    "west_virginia":WEST_VIRGINIA,
    "wisconsin":WISCONSIN,
    "wyoming":WYOMING,
    "usa_rectangle":USA_RECTANGLE,
}

export const StateImage = (imageName) => {
    const state_name = imageName.trim().toLowerCase().replace(/ /g, '_');
    return state_median_income[state_name]
};

export default StateImage






















































